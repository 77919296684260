import React from 'react';


const ApplyPage = () => {

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <div className="wpo-wpo-blog-content clearfix">
                            <div className="post">
                                <h2>What our funding is about</h2>
                                <p>The Opekete Foundation is in memory of Late Mrs Florence Bamidele Makanjuola who built a career in teaching and nursing.
                                    Aside her passion for the promoting female self dependence , Mrs Makanjuola in her lifetime expressed
                                    dissatisfaction at the current state of education and calibre of teachers available. She also derived passion in encouraging young girls
                                    who want to study nursing to do so, often trying to get one of her grand daughters to study nursing because she had the gift of caring for others.
                                    In the spirit of acting on her desire to see better teachers and nurses, Mrs Makanjuola set up the okpekete foundation with a few students being beneficiaries. To keep her dream alive, her children and grandchildren have decided to keep continue with the scholarships via the Florence Fund</p>

                                <h2>Scholarship Amount</h2>
                                <h3>Secondary School Students</h3>
                                <p>Maximum of 350,000 per annum for each student.
                                </p>
                                <h3>University Students</h3>
                                <p>Maximum of 650,000 per annum for each student.
                                </p>


                                <h2>Eligibility Criteria</h2>
                                <p>Prospective Beneficiaries Must Be:</p>
                                <ul>
                                    <li>•	A female Nigerian Citizen</li>
                                    <li>•	Have gained admission/ or currently enrolled into a public university or Senior Secondary School while applying for this opportunity. </li>
                                    <li>•	Applicants must be attending or intending to attend a public or federal government owned Senior Secondary School or University </li>
                                    <li>•   Intending and/or willing to study Teaching Science Technology Engineering & Mathematics (STEM) or Nursing in the University                   </li>
                                </ul>
                                <br />
                                <h2>Academic Qualifications</h2>
                                <h3>Secondary School Applicants</h3>
                                <ul>
                                    <li>•	Minimum of 5 Distinctions (A or B) in English, Mathematics and any other 3 major subjects in Junior WAEC (Secondary applicants)</li>
                                </ul>

                                <h3>University Applicants</h3>
                                <ul>
                                    <li>•	Minimum of 5 Distinctions (A1- B3) in English, Mathematics and any other 3 major subjects in WAEC / NECO at one sitting</li>
                                    <li>•	Meet the required cut-off score in JAMB for the course and school applied to.</li>
                                </ul>

                                {/* <h2>Continued  Qualification</h2>
                                <p>Awardees are expected to maintain a
                                    minimum of 4 distinctions and 2 merits during
                                    the duration of their studies or an average of
                                    75%
                                </p> */}
                                <h2>Apply Now</h2>
                                <p>We believe OPEKETE could be the perfect fit for you, If you meet the required qualifications, 
    <a href="https://forms.gle/gnt25TKuMokCsawP9" style={{color:'#f0628a'}} > click here to apply now  </a> and upload your supporting documents. Application closes on the 15th of September 2024 to allow people to get their results</p>
                            

                                <h2>Selection </h2>
                                <p>The submissions shall be screened by an independent consultant </p>

                                <h2>Announcement of Awardees</h2>
                                <p>The Announcement of Awardees shall be made on or around the 30th of October, 2024</p>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </section>
    )

}

export default ApplyPage;