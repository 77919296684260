import React from 'react'
import abimg from '../../images/Scholarshipflyer.png'
import VideoModal from '../ModalVideo'
import { Link } from 'react-router-dom'
import './style.css'

const Event = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);

    }
    return (
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="row" >
                    <div className="col-lg-5 col-md-12 colsm-12">
                        <div className="wpo-about-img-3">
                            <img src={abimg} alt=""  height='400'/>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>Scholarships</span>
                                <h2> 24/25 Florence Bamidele Makanjuola Scholarship For Women </h2>
                            </div>
                            <p>'We are thrilled to announce that applications for the prestigious 2024/2025 Florence Bamidele Makanjuola Scholarship for Women are now open!

This scholarship, established in honor of Florence Bamidele Makanjuola's enduring commitment to women's education and empowerment, aims to support exceptional women who are pursuing higher education and aspire to make a significant impact in their communities and beyond.
                                {/* - dedication to God, love and sacrifice for family and humanity, hard-work, diligence, selfless service to the community and triumph against many odds. */}
                            </p>
                            <p>To find out more about the application requirements and how to apply, please click the link below.
                                {/* <ol>
                                    <li>	The First Biennial FBM Roundtable on Promoting Female Education </li>
                                    <li>Launch & Fundraiser for the Florence Fund, an initiative of the Opekete Foundation </li>
                                    <li>	Pre-launch of an Autobiography of FBM’s Inspiring Life of Service </li>

                                </ol> */}
                            </p>

                            <div className="btns">
                            
                            <Link to="/apply" className="theme-btn">Learn More
                            </Link>
                                {/* <ul>
                                    <li className="video-holder">
                                        <VideoModal />
                                    </li>
                                    <li className="video-text">
                                        Watch Our Video
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Event;